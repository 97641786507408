<template>
    <div>
      <!-- 活动周标题 -->
      <top :islist="islist"></top>
      <!-- 模块标题 -->
      <div class="category">
        <div class="title"><strong><span>科普•</span>讲堂</strong></div>
        <div class="subTitle"><span>Popular Science Lecture Hall</span></div>
      </div>
      <!-- tab -->
      <div class="subnav">
        <span :class="active==2?'active':''" @click="active=2">科普知识</span>
        <span :class="active==1?'active':''" @click="active=1">政策宣讲</span>
      </div>
      <!-- 列表1 -->
      <div v-show="active==2">
        <ul class="list">
          <li v-for="item in list" :key="item.id" @click="goDetail(item.id,item.site)">
            <div class="imgbox">
              <img :src="item.pictureVideo" />
            </div>
            <div class="title">
              <p>{{item.theme}}</p>
              <span>{{item.releaseTime.slice(0,10)}}</span>
            </div>
          </li>
        </ul>
        <pagination
        :total="total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="getList"
      />
      </div>
      <div v-show="active==1">
        <ul class="list">
          <li v-for="item in list2" :key="item.id" @click="goDetail(item.id,item.site)">
            <div class="imgbox">
              <img :src="item.pictureVideo" />
            </div>
            <div class="title">
              <p>{{item.theme}}</p>
              <span>{{item.releaseTime.slice(0,10)}}</span>
            </div>
          </li>
        </ul>
        <pagination
        :total="total2"
        :page.sync="listQuery2.page"
        :limit.sync="listQuery2.limit"
        @pagination="getList2"
      />
      </div>
      <page-footer></page-footer>
    </div>
</template>

<script>
import Top from '@/views/mobile/components/Top.vue'
import PageFooter from './components/PageFooter.vue';
import Pagination from './components/Pagination.vue';
import { getLectures } from '@/api/mobile.js'
export default {
  name: 'MLecture',
  components:{
    Top,
    PageFooter,
    Pagination
  },
  data() {
    return {
      islist:true,
      total:0,
      listQuery:{
        page:1,
        limit:5
      },
      list:[],
      active:2,
      total2:0,
      listQuery2:{
        page:1,
        limit:5
      },
      list2:[],
    };
  },
  created(){
    this.getList()
    this.getList2()
  },
  methods:{
    getList(){
      let params = {
        pageNo:this.listQuery.page,
        pageSize:this.listQuery.limit,
        flag:2
      }
      getLectures(params).then(res => {
        this.total = res.data.total
        this.list = res.data.list
      })
    },
    getList2(){
      let params = {
        pageNo:this.listQuery2.page,
        pageSize:this.listQuery2.limit,
        flag:1
      }
      getLectures(params).then(res => {
        this.total2 = res.data.total
        this.list2 = res.data.list
      })
    },
    goDetail(id,site){
      if(site){
        window.open(site,"_blank")
      }else{
        this.$router.push('/zcxj/'+id)
      }
    }
  }
};
</script>

<style scoped lang="scss">
// 分类标题
.category{
  text-align: center;
  position: relative;
}
.category::after{
  display: block;
  content: '';
  width: 460px;
  height: 42px;
  border:1px solid #1046a2;
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.category .title{
  font-size: 36px;
  color: #333;
}
.category .title strong span{
  color:#1046a2;
}
.category .title strong{
  padding:0 10px;
  background-color: #fff;
}
.category .subTitle{
  font-size: 20px;
  color: #333;
}
.category .subTitle span{
  padding:0 10px;
  background-color: #fff;
}
// 列表
.list{
  padding: 10px 30px 30px 30px;
}
.list li{
  margin-top:30px;
}
.list li .img-wrapper{
  width: 690px;
  height: 380px;
  overflow: hidden;
  position: relative;
  background: url('../../assets/img/bg.jpg') no-repeat;
  background-size: cover;
  padding: 90px 20px 20px 60px;
  color: #fff;
}
.list li .imgbox{
  width: 690px;
  height: 380px;
  overflow: hidden;
}
.list li .imgbox img{
  width: 690px;
  height: 380px;
  object-fit: cover;
}
.list li .img-wrapper h3{
  font-size: 44px;
}
.list li .img-wrapper span{
  font-size: 26px;
  margin-top: 40px;
}
.list .title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0px;
  border-bottom: 1px solid #ccc;
}
.list .title p{
  font-size: 30px;
  color: #333;
  flex: 1;
  padding-right: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.list .title span{
  font-size: 22px;
  color:#999;
}
.subnav{
  background-color: #e8edf3;
  line-height: 60px;
  color:#999;
  display: flex;
  border-radius: 30px;
  margin: 30px 30px 0 30px;
}
.subnav span{
  flex:1;
  text-align: center;
  font-size: 28px;
}
.subnav span.active{
  background-color: #1045a1;
  color:#fff;
  border-radius: 30px;
}
</style>
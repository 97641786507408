<template>
  <div style="background: #f4f4f7">
    <Nav ref="navchild"></Nav>
    <div class="banner-width">
      <Banner></Banner>
    </div>
    <div class="mainwidth">
      <Title>
        <template v-slot:one>
          <el-divider content-position="center">科普讲堂</el-divider>
        </template>
        <template v-slot:two>
          <span class="sub1">政策宣讲</span>
        </template>
      </Title>
      <div class="list">
        <div
          class="item"
          v-for="(item, index) in list"
          :key="index"
          @click="gotoKpjtDetails(item.id)"
        >
          <img :src="item.pictureVideo"  />
          <div class="line">
            <div class="linetxt">{{ item.theme }}</div>
            <div class="author">{{ item.releaseTime }}</div>
          </div>
        </div>
      </div>
      <div class="page">
        <el-pagination
          background
          :page-size="listPage.pageSize"
          layout="prev, pager, next"
          :current-page="listPage.currentPage"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :total="listPage.totalCount"
        ></el-pagination>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Nav from "@/views/pc/components/Nav";
import Banner from "@/views/pc/components/Banner";
import Footer from "@/views/pc/components/Footer";
import Title from "@/views/pc/components/Title";
import { kpjtList } from "@/api/pc.js";
export default {
  name: "PolicyPropaganda",
  data() {
    return {
       flag:1,
      listPage: {
        // pageSize代表每页显示的数目
        pageSize: 6,
        // currentPage代表当面的页数
        currentPage: 1,
        // totalCount列表的总数
        totalCount: 5,
      },
      list: [],
    };
  },
  created() {
    this.getKpjtList();
  },
  mounted() {
    var arr = this.$route.path.split("/");
    this.$refs.navchild.setNavIndex("/" + arr[1]);
  },
  methods: {
    // pageSize 改变时会触发的事件
    handleSizeChange(val) {
      this.listPage.pageSize = val;
      // 当pageSize发生变化后需重新查询列表
      this.getKpjtList();
    },
    // currentPage 改变时会触发
    handleCurrentChange(val) {
      this.listPage.currentPage = val;
      // 当currentPage发生变化后需重新查询列表
      this.getKpjtList();
    },
    getKpjtList() {
      let params = {
        pageNo: this.listPage.currentPage,
        pageSize: this.listPage.pageSize,
        flag:this.flag
      };
      kpjtList(params).then((res) => {
        this.list = res.data.list;
        this.listPage.totalCount = res.data.total;
      });
    },
    gotoKpjtDetails(id) {
      this.$router.push(`/zcxj/${id}`);
    },
  },
  components: { Footer, Nav, Title, Banner },
};
</script>
<style scoped>
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: #fff;
}
</style>
<style scoped lang="scss">
.page {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.sub1 {
     background: #067fd8;
    color: #fff;
    width: 140px;
    text-align: center;
    height: 22px;
    line-height: 22px;
}
.banner-width {
  background: url("~@/assets/pcimg/bg1.jpg")  center no-repeat;
}

.mainwidth {
  width: 1200px;
  margin: 0 auto;

  .list {
    display: flex;

    flex-wrap: wrap;
    margin-top: 20px;
    margin-left: -20px;
    .item {
      background: url("../../assets/pcimg/list1.png") no-repeat;
      width: 383px;
      height: 238px;
      position: relative;
      margin-top: 20px;
      margin-left: 20px;
      cursor: pointer;
        img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
      }
      h3 {
        font-size: 24px;
        padding-left: 30px;
        color: #fff;
        padding-top: 45px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 20px;
      }
      span {
        font-size: 18px;
        color: #fff;
        padding-left: 30px;
        padding-top: 10px;
        display: block;
      }
          .line {
        position: absolute;
        width: 383px;
        height: 40px;
        background: #067fd8;
        font-size: 20px;
        color: #fff;
        padding-left: 10px;
        line-height: 40px;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        .linetxt {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 238px;
        }
        .author {
          font-size: 16px;
          padding-right: 10px;
          text-align: right;
        }
      }
    }
  }
}
</style>